<template>
    <v-model-table :data-function="getOrdersWithoutGamesReport" :columns="columns" :show-toolbar="false">
        <template v-slot:column_order_id="{ row }">
            <v-link :to="{ name: 'orders.show', params: { order: row.order_id } }">
                {{ (row.order_id) }}
            </v-link>
        </template>

        <template v-slot:column_source_order_id="{ row }">
            <v-link :href="`${getOrganizationDomain(row.organization_id)}/wp-admin/post.php?post=${row.source_order_id}&action=edit`" target="_blank">
                {{ (row.source_order_id) }}
            </v-link>
        </template>

        <template v-slot:column_product_name="{ row }">
            <v-link :href="`${getOrganizationDomain(row.organization_id)}/wp-admin/post.php?post=${row.source_product_id}&action=edit`" target="_blank">
                {{ (row.product_name) }}
            </v-link>
        </template>

        <template v-slot:column_customer_name="{ row }">
            <v-text>
                {{ row.customer_name | customerName }}
            </v-text>
        </template>
    </v-model-table>
</template>

<script>
import ReportService from "@/services/modules/report-service";

export default {
    data() {
        return {
            getOrdersWithoutGamesReport: () => ReportService.getOrdersWithoutGamesReport(),
            columns: {
                order_id: {
                    label: "Order",
                    fullWidth: false,
                },
                source_order_id: {
                    label: "Source Order",
                    fullWidth: false,
                },
                customer_name: {
                    label: "Customer",
                    fullWidth: false,
                },
                product_name: {
                    label: "Product",
                    fullWidth: false,
                },
                created_at: {
                    label: "Created",
                    fullWidth: false,
                    align: "right",
                },
            },
        };
    },
    methods: {
        getOrganizationDomain(organizationId) {
            // Sherpas of Destiny
            if (organizationId === 1) {
                return "https://sherpasofdestiny.com";
            }

            // Sherpas Boosting
            if (organizationId === 2) {
                return "https://sherpasboosting.com/wp";
            }

            return null;
        },
    },
};
</script>
