var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-model-table',{attrs:{"data-function":_vm.getOrdersWithoutGamesReport,"columns":_vm.columns,"show-toolbar":false},scopedSlots:_vm._u([{key:"column_order_id",fn:function(ref){
var row = ref.row;
return [_c('v-link',{attrs:{"to":{ name: 'orders.show', params: { order: row.order_id } }}},[_vm._v(" "+_vm._s((row.order_id))+" ")])]}},{key:"column_source_order_id",fn:function(ref){
var row = ref.row;
return [_c('v-link',{attrs:{"href":((_vm.getOrganizationDomain(row.organization_id)) + "/wp-admin/post.php?post=" + (row.source_order_id) + "&action=edit"),"target":"_blank"}},[_vm._v(" "+_vm._s((row.source_order_id))+" ")])]}},{key:"column_product_name",fn:function(ref){
var row = ref.row;
return [_c('v-link',{attrs:{"href":((_vm.getOrganizationDomain(row.organization_id)) + "/wp-admin/post.php?post=" + (row.source_product_id) + "&action=edit"),"target":"_blank"}},[_vm._v(" "+_vm._s((row.product_name))+" ")])]}},{key:"column_customer_name",fn:function(ref){
var row = ref.row;
return [_c('v-text',[_vm._v(" "+_vm._s(_vm._f("customerName")(row.customer_name))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }